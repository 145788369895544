import { useState } from 'react';

import { useMediaQuery } from '@mui/material';

import AsideMenuDesktop from './AsideMenuDesktop';
import HeaderMobile from './HeaderMobile';
import Menu from './Menu';

function NavigationBar() {
  const [openCommonMenu, setOpenCommonMenu] = useState(false);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);

  const matchSM = useMediaQuery('(max-width:744px)');

  return (
    <>
      {matchSM && (
        <HeaderMobile
          openCommonMenu={openCommonMenu}
          openProfileMenu={openProfileMenu}
          setOpenCommonMenu={setOpenCommonMenu}
          setOpenProfileMenu={setOpenProfileMenu}
        />
      )}
      {matchSM && (
        <Menu
          openCommonMenu={openCommonMenu}
          openProfileMenu={openProfileMenu}
          closeModal={() => {
            setOpenCommonMenu(false);
            setOpenProfileMenu(false);
          }}
        />
      )}
      {!matchSM && <AsideMenuDesktop />}
    </>
  );
}
export default NavigationBar;
