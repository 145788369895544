import { instance } from './instance';

export async function fetchFeedbacks({ id, params }) {
  const { data: result } = await instance.get(`/feedbacks/${id}`, {
    params,
  });
  return result;
}
export async function getFeedbacksTotalCount({ id, params }) {
  const { data: result } = await instance.get(`/feedbacks-count/${id}`, {
    params,
  });
  return result;
}

export async function addFeedback(data) {
  const { data: result } = await instance.post('/feedback/add', data);
  return result;
}

export async function addTag(data) {
  const { data: result } = await instance.post('/feedback/tag', data);
  return result;
}

export async function addComment(data) {
  const { data: result } = await instance.post('feedback/comment', data);
  return result;
}
export async function archiveFeedback(data) {
  const { data: result } = await instance.patch('/feedback/archive', data);
  return result;
}

export async function getTranslate(data) {
  const { data: result } = await instance.post('/translate', data);
  return result;
}

export async function getTags() {
  const { data: result } = await instance.get('/tags');
  return result;
}

export async function detachTag(data) {
  const { data: result } = await instance.patch('/feedback/tag-detach', data);
  return result;
}
