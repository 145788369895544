export const styles = {
  wrapper: {
    padding: '24px 16px',

    width: '250px',
    bgcolor: 'background.secondary',
    backgroundImage: 'linear-gradient(0deg, rgba(103, 80, 164, 0.08), rgba(103, 80, 164, 0.08))',
  },
  nav: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media screen and (min-width: 745px)': {
      height: '90vh',
    },
  },
  logoWrapper: {
    paddingLeft: '16px',
    marginBottom: '50px',
  },
};
