import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import useAuthState from 'shared/hooks/useAuthState';

import { styles } from './LinkTariffTheme';

function LinkTariff() {
  const { t } = useTranslation();
  const { user } = useAuthState();
  const subscription = user?.subscription?.name || 'base';

  return (
    <Link style={styles.linkTariff}>
      <Typography sx={styles.textTariff}>
        {t('commonMenu.tariffLink')} {subscription.toUpperCase()}
      </Typography>
    </Link>
  );
}
export default LinkTariff;
