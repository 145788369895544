import { stylesVars } from 'styles/stylesVars';
export const styles = {
  wrapperSwitch: {
    marginTop: '27px',
    ...stylesVars.flexBox,
    marginRight: '10px',
    marginLeft: '10px',
  },
  lang: {
    fontFamily: stylesVars.fonts.roboto,
    fontWeight: '500',
    fontSize: stylesVars.fonts.fontSize,
    color: stylesVars.light.primary,
  },
};
