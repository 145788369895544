import { Outlet, Navigate, useNavigate } from 'react-router-dom';

import useAuthState from 'shared/hooks/useAuthState';

function PrivateRoute() {
  const navigate = useNavigate();
  const { isLogin } = useAuthState();

  return isLogin ? <Outlet /> : navigate('/signin');
}

export default PrivateRoute;
