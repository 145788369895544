import { createAsyncThunk } from '@reduxjs/toolkit';

export const createOperation = (name, request, condition) => {
  return createAsyncThunk(
    name,
    async (data, { rejectWithValue }) => {
      try {
        const response = await request(data);
        return response;
      } catch (error) {
        console.log('error', error.response.data.message);
        return rejectWithValue({ ...error, message: error.response.data.message });
      }
    },
    { condition },
  );
};

export const pending = (store) => ({ ...store, loading: true, error: null });

export const rejected = (store, { payload }) => ({
  ...store,
  loading: false,
  error: payload,
});

export const isRejectedAction = (action) => {
  return action.type.endsWith('rejected');
};
