import { instance } from './instance';

export async function getQR({ id }) {
  const { data: result } = await instance.get(`/qr/${id}`);
  return result;
}

export async function getQRs({ params }) {
  const { data: result } = await instance.get('/qrs', {
    params,
  });
  return result;
}

export async function addQRcode(data) {
  const { data: result } = await instance.post('/qr/create', data);
  return result;
}
export async function updateSizeQRcode({ size, _id, companyID }) {
  const { data: result } = await instance.put(`/qr/update/${_id}`, { data: size, companyID });
  return result;
}
export async function updateQRcode({ data, _id, companyID }) {
  const { data: result } = await instance.put(`/qr/update/${_id}`, { data, companyID: data.company });
  return result;
}

export async function deleteQRcode(id) {
  const { data: result } = await instance.delete(`/qr/${id}`);
  return { id, ...result };
}
