import { createSlice } from '@reduxjs/toolkit';
import {
  signupRequest,
  confirmSignupRequest,
  createUserRequest,
  signinRequest,
  facebookAuthRequest,
  googleAuthRequest,
  signoutRequest,
  getCurrentRequest,
  updateUserRequest,
} from '../auth/auth-operations';

import { pending, rejected, isRejectedAction } from 'shared/utils/utils';

const initialState = {
  user: {},
  verify: false,
  token: '',
  ttl: '',
  isLogin: false,

  loading: false,
  error: null,
};

const fulfilled = (store, { payload }) => ({
  ...initialState,
  user: { ...payload.data.user },
  token: payload.data.token,
  ttl: payload.data.ttl,
  isLogin: true,
  verify: true,
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    addSuccessSignUp: (store, { payload }) => ({ ...store, ...payload }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(signupRequest.pending, pending)
      .addCase(signupRequest.fulfilled, (store, { payload: { data } }) => ({
        ...initialState,
        user: { ...data },
      }))

      .addCase(confirmSignupRequest.pending, pending)
      .addCase(confirmSignupRequest.fulfilled, (store, { payload: { data } }) => ({
        ...initialState,
        user: { ...data },
        verify: data.verify,
      }))

      .addCase(createUserRequest.pending, pending)
      .addCase(createUserRequest.fulfilled, (store, { payload }) => ({
        ...initialState,
        user: {
          ...payload.data.data,
        },
        isLogin: false,
        verify: true,
      }))

      .addCase(signinRequest.pending, pending)
      .addCase(signinRequest.fulfilled, fulfilled)

      .addCase(googleAuthRequest.pending, pending)
      .addCase(googleAuthRequest.fulfilled, fulfilled)

      .addCase(facebookAuthRequest.pending, pending)
      .addCase(facebookAuthRequest.fulfilled, fulfilled)

      .addCase(signoutRequest.pending, pending)
      .addCase(signoutRequest.fulfilled, () => ({ ...initialState }))

      .addCase(updateUserRequest.pending, pending)
      .addCase(updateUserRequest.fulfilled, (store, { payload }) => ({
        ...store,
        loading: false,
        user: payload.data.data,
      }))

      .addCase(getCurrentRequest.pending, pending)
      .addCase(getCurrentRequest.fulfilled, (store, { payload }) => ({
        ...store,
        user: { ...payload.data.user, lastName: payload.data.user?.lastName || '' },
        loading: false,
        isLogin: true,
      }))

      .addMatcher(isRejectedAction, rejected);
  },
});
export default authSlice.reducer;
