import { Link, useNavigate } from 'react-router-dom';

import { List, ListItem, ListItemButton, AppBar, Box, IconButton } from '@mui/material';
import { Notifications } from '@mui/icons-material';
import useNoitificationsState from 'shared/hooks/useNotificationsState';
import useAuthState from 'shared/hooks/useAuthState';

import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';

import Logo from 'shared/components/Logo';

import { styles } from './HeaderMobileTheme';

function HeaderMobile({ openCommonMenu, openProfileMenu, setOpenCommonMenu, setOpenProfileMenu }) {
  const navigate = useNavigate();
  const { token } = useAuthState();

  const handleStateCommonMenu = () => {
    setOpenCommonMenu(!openCommonMenu);
    setOpenProfileMenu(false);
  };
  const handleStateProfileMenu = () => {
    setOpenProfileMenu(!openProfileMenu);
    setOpenCommonMenu(false);
  };

  const { notifications } = useNoitificationsState();
  return (
    <AppBar sx={styles.header}>
      <Box component="nav">
        <List sx={styles.list}>
          <ListItem>
            <ListItemButton onClick={handleStateCommonMenu} sx={styles.listItem}>
              <MenuIcon sx={styles.menuIcon} />
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ ...styles.listItem, justifyContent: 'center' }}>
            <Link
              to="/"
              onClick={() => {
                setOpenCommonMenu(false);
                setOpenProfileMenu(false);
              }}>
              <Logo />
            </Link>
          </ListItem>
          <ListItem sx={{ ...styles.listItem, justifyContent: 'flex-end' }}>
            {notifications?.length > 0 && (
              <IconButton
                onClick={() => {
                  navigate('/notifications');
                }}>
                <Notifications sx={{ ...styles.menuIcon, fontSize: '18px', color: 'primary.main' }} />
              </IconButton>
            )}
            {token && (
              <IconButton onClick={handleStateProfileMenu}>
                <AccountCircleRoundedIcon sx={styles.menuIcon} />
              </IconButton>
            )}
          </ListItem>
        </List>
      </Box>
    </AppBar>
  );
}
export default HeaderMobile;
