import { Outlet, Navigate, useNavigate } from 'react-router-dom';

import useAuthState from 'shared/hooks/useAuthState';

function PublicRoute() {
  const { isLogin } = useAuthState();

  return !isLogin ? <Outlet /> : <Navigate to="/signin" />;
}

export default PublicRoute;
