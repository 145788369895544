import { createOperation } from 'shared/utils/utils';

import { getCompanies, addCompany, updateCompany, deleteCompany } from 'shared/api/companies';
import { addEmployee, deleteEmployee, updateEmployee } from 'shared/api/employees';

export const getCompaniesRequest = createOperation('companies/fetch', getCompanies);
export const addCompanyRequest = createOperation('companies/add', addCompany);
export const updateCompanyRequest = createOperation('companies/update', updateCompany);
export const deleteCompanyRequest = createOperation('companies/delete', deleteCompany);

export const addEmployeeRequest = createOperation('companies/addEmployee', addEmployee);
export const deleteEmployeeRequest = createOperation('company/deleteEmployee', deleteEmployee);
export const updateEmployeeRequest = createOperation('company/updateEmployee', updateEmployee);
