import { stylesVars } from 'styles/stylesVars';

export const styles = {
  linkTariff: {
    textDecoration: 'none',
    ...stylesVars.flexBox,
    border: `1px solid ${stylesVars.light.primary}`,
    borderRadius: '20px',
    padding: '10px 16px',
  },
  textTariff: {
    fontSize: stylesVars.fonts.fontSize,
    fontWeight: stylesVars.fonts.fontWeight,
    color: stylesVars.light.primary,
  },
};
