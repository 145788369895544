import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Box, Typography, Slide } from '@mui/material';

import CustomIconButton from '../CustomIconButton/CustomIconButton';

import { styles } from './CustomNotificationTheme';

function CustomNotification(props) {
  const { t } = useTranslation();
  const { message, type, dispatch, id: notifID } = props;

  const [exit, setExit] = useState(true);
  const [width, setWidth] = useState(0);
  const [intervalID, setIntervalID] = useState(null);

  const handleStartTimer = () => {
    const id = setInterval(() => {
      setWidth((prev) => {
        if (prev < 100) {
          return prev + 0.5;
        }

        clearInterval(id);
        return prev;
      });
    }, 20);

    setIntervalID(id);
  };

  const handlePauseTimer = () => {
    clearInterval(intervalID);
  };

  const handleCloseNotification = () => {
    handlePauseTimer();
    setExit(false);
    setTimeout(() => {
      props.dispatch({
        type: 'REMOVE_NOTIFICATION',
        id: props.id,
      });
    }, 400);
  };

  useEffect(() => {
    if (width === 80) {
      // Close notification
      handleCloseNotification();
    }
  }, [width]);

  useEffect(() => {
    handleStartTimer();
  }, []);

  return (
    <Slide direction="left" in={exit} mountOnEnter unmountOnExit onClick={handleCloseNotification}>
      <Box sx={styles.box} onMouseEnter={handlePauseTimer} onMouseLeave={handleStartTimer}>
        <CustomIconButton
          nameIcon="close"
          styleIcon={{ fontSize: '14px' }}
          styleBtn={{ position: 'absolute', top: '10px', right: '14px' }}
          onClick={handleCloseNotification}
        />
        <Typography sx={styles.text}>
          {message ? t(`notifications.errors.${message}`) : t('notifications.errors.Common')}
        </Typography>
        <Box sx={{ ...styles.boxTimer, width: `${width}%` }} />
      </Box>
    </Slide>
  );
}
export default CustomNotification;
