export const styles = {
  box: {
    position: 'fixed',
    top: '0',
    right: '3%',

    // boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
    bgcolor: 'background.secondary',
    backgroundImage: 'linear-gradient(0deg, rgba(113, 90, 174, 0.11), rgba(113, 90, 174, 0.11))',
    p: '18px 24px',
    pt: '30px',
    borderRadius: '28px',

    minHeight: '100px',
    width: '312px',

    margin: '0 auto',
    marginTop: '24px',
    zIndex: '99999',
  },
  text: {
    fontSize: '11px',
    mb: '5px',
    color: 'text.secondary',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '20px',
  },
  boxTimer: {
    bgcolor: 'primary.main',
    height: '5px',
    position: 'absolute',
    bottom: '18px',
  },
};
