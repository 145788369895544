import { createSlice } from '@reduxjs/toolkit';
import {
  getCompaniesRequest,
  addCompanyRequest,
  updateCompanyRequest,
  deleteCompanyRequest,
  addEmployeeRequest,
  deleteEmployeeRequest,
  updateEmployeeRequest,
} from './companies-operations';
import { deleteQRcodeRequest } from '../qrCodes/qrCodes-operations';

import { pending, rejected, isRejectedAction } from 'shared/utils/utils';

const initialState = {
  companies: [],

  loading: false,
  customLoading: false,
  error: null,
};

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCompaniesRequest.pending, pending)
      .addCase(getCompaniesRequest.fulfilled, (store, { payload: { data } }) => ({
        ...store,
        loading: false,
        companies: data,
      }))

      .addCase(addCompanyRequest.pending, pending)
      .addCase(addCompanyRequest.fulfilled, (store, { payload: { data } }) => ({
        ...store,
        loading: false,
        companies: store.companies?.length
          ? [...store.companies?.filter(({ _id }) => _id !== data._id), data?.company]
          : [data?.company],
      }))

      .addCase(updateCompanyRequest.pending, pending)
      .addCase(
        updateCompanyRequest.fulfilled,
        (
          store,
          {
            payload: {
              data: { data: companyObj },
            },
          },
        ) => ({
          ...store,
          loading: false,
          companies: [...store.companies?.filter(({ _id }) => _id !== companyObj?._id), companyObj],
        }),
      )
      .addCase(deleteQRcodeRequest.pending, (store, { payload }) => ({ ...store, customLoading: true }))
      .addCase(deleteQRcodeRequest.fulfilled, (store, { payload: { id } }) => ({
        ...store,
        customLoading: false,
        companies: [
          ...store.companies.map((company) => ({
            ...company,
            qrCodes: company?.qrCodes.filter(({ _id }) => _id !== id),
          })),
        ],
      }))

      .addCase(deleteCompanyRequest.pending, (store, { payload }) => ({ ...store, customLoading: true }))
      .addCase(deleteCompanyRequest.fulfilled, (store, { payload }) => ({
        ...store,
        customLoading: false,
        companies: [...store.companies?.filter(({ _id }) => _id !== payload)],
      }))

      .addCase(addEmployeeRequest.pending, pending)
      .addCase(addEmployeeRequest.fulfilled, (store, { payload: { data } }) => ({
        ...store,
        loading: false,
        companies: store.companies?.length
          ? [...store.companies?.filter(({ _id }) => _id !== data.company._id), data?.company]
          : [data?.company],
      }))

      .addCase(deleteEmployeeRequest.pending, (store, { payload }) => ({
        ...store,
        customLoading: true,
      }))
      .addCase(deleteEmployeeRequest.fulfilled, (store, { payload }) => ({
        ...store,
        loading: false,
        customLoading: false,
        companies: store.companies?.map((company) => ({
          ...company,
          employees: company.employees.filter((employee) => employee._id !== payload._id),
        })),
      }))

      .addCase(updateEmployeeRequest.pending, pending)
      .addCase(updateEmployeeRequest.fulfilled, (store, { payload: { data } }) => ({
        ...store,
        loading: false,
        companies: store.companies?.length
          ? [...store.companies?.filter(({ _id }) => _id !== data.company._id), data?.company]
          : [data?.company],
      }))

      .addMatcher(isRejectedAction, rejected);
  },
});
export default companiesSlice.reducer;

/**
 * ({
        ...store,
        customLoading: false,
        companies: [
          ...store.companies?.filter(
            (company) => company._id !== company.employees.find(({ _id }) => _id === payload)._id,
          ),
        ],
      })
 */
