import { useLocation } from 'react-router-dom';

import { Container } from '@mui/material';

function ContentContainer({ children }) {
  const { pathname } = useLocation();

  return (
    <Container
      sx={{
        paddingTop: '44px',
        pb: '44px',
        '@media screen and (max-width: 744px)': {
          padding: '48px 16px',
          paddingTop:
            pathname === '/add-feedback' || pathname === '/notifications' || pathname === '/contact-us'
              ? '0px'
              : '125px',
        },
      }}
      disableGutters
      maxWidth={false}>
      {children}
    </Container>
  );
}

export default ContentContainer;
