import { createAsyncThunk } from '@reduxjs/toolkit';
import { createOperation } from 'shared/utils/utils';
import {
  signup,
  confirmSignUp,
  createUser,
  signin,
  googleAuth,
  facebookAuth,
  signout,
  getCurrent,
  updateUser,
} from 'shared/api/auth';

export const signupRequest = createOperation('auth/signup', signup);
export const confirmSignupRequest = createOperation('auth/confrimSignup', confirmSignUp);
export const createUserRequest = createOperation('auth/createUser', createUser);
export const updateUserRequest = createOperation('auth/updateUser', updateUser);

export const signinRequest = createOperation('auth/signin', signin);

export const googleAuthRequest = createOperation('auth/googleAuth', googleAuth);
export const facebookAuthRequest = createOperation('auth/facebookAuth', facebookAuth);

export const signoutRequest = createOperation('auth/signout', signout);

export const getCurrentRequest = createAsyncThunk(
  'auth/current',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const response = await getCurrent(auth.token);
      return response;
    } catch (error) {
      return rejectWithValue({ ...error, message: error.response.data.message });
    }
  },
  {
    condition: (_, { getState }) => {
      const { auth } = getState();
      if (!auth.token) {
        return false;
      }
    },
  },
);
