export const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          primary: {
            main: '#6750A4',
          },
          divider: '#79747E',
          background: {
            main: '#F7F6FA',
            paper: '#F7F6FA',
            second: '#E8DEF8',
            secondary: '#FFFBFE',
            item: '#F3EDF7',
            notify: '#E6E1E5',
          },
          text: {
            primary: '#1C1B1F',
            secondary: '#49454F',
          },
        }
      : {
          // palette values for dark mode
          primary: {
            main: '#6750A4',
          },
          divider: '#79747E',
          background: {
            main: '#F7F6FA',
            paper: '#FFFBFE',
          },
          text: {
            primary: '#fff',
            secondary: '#FFFBFE',
          },
        }),
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },

  fontSizes: {
    xs: '16px',
    s: '16px',
    m: '16px',
    l: '16px',
    xl: '16px',
  },

  fontWeights: {
    normal: 500,
    bold: 800,
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 360,
      md: 744,
      lg: 1200,
      xl: 1536,
    },
  },
  radii: {
    none: '0',
    sm: '4px',
    md: '8px',
    lg: '16px',
    round: '50%',
  },
  transition: {
    color: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
    all: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1)',
  },
});

/** //paper: '#FFFBFE', - old -changed by search */

/** 'linear-gradient(0deg, rgba(113, 90, 174, 0.11), rgba(113, 90, 174, 0.11)), #FFFBFE' */
