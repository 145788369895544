export const styles = {
  container: {
    display: 'flex',
    minHeight: '100vh',
    '@media screen and (max-width: 743px)': {
      height: '100vh',
      flexDirection: 'column',
    },
  },
};
