import { Dialog, Box } from '@mui/material';

import CommonMenu from './CommonMenu';
import ProfileMenu from './ProfileMenu';

import { styles } from './MenuTheme';

function Menu({ openCommonMenu, openProfileMenu, closeModal }) {
  return (
    <Dialog open={openCommonMenu || openProfileMenu} sx={styles.dialog} fullScreen>
      <Box sx={styles.wrapper}>
        {openProfileMenu && <ProfileMenu closeModal={closeModal} />}
        {openCommonMenu && <CommonMenu closeModal={closeModal} />}
      </Box>
    </Dialog>
  );
}
export default Menu;
