import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import ThemesProvider from 'shared/providers/ThemesProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import NotificationProvider from 'shared/providers/NotificationProvider';

import { CssBaseline } from '@mui/material';

import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';

import './i18n';

import Loader from 'shared/components/Loader/Loader';
import './styles/index.css';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ThemesProvider>
      <NotificationProvider>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <Provider store={store}>
            <PersistGate loading={<Loader />} persistor={persistor}>
              <CssBaseline />
              <App />
            </PersistGate>
          </Provider>
        </GoogleOAuthProvider>
      </NotificationProvider>
    </ThemesProvider>
  </BrowserRouter>,
);
