import { createSlice } from '@reduxjs/toolkit';

import {
  fetchNotificationsRequest,
  inactiveNotificationRequest,
  inactiveNotificationsRequest,
} from './notifications-operation';
import { archiveFeedbackRequest } from '../feedbacks/feedbacks-operations';
import { updateCompanyRequest, deleteEmployeeRequest, updateEmployeeRequest } from '../companies/companies-operations';
import { updateQRcodeRequest, updateSizeQRcodeRequest, deleteQRcodeRequest } from '../qrCodes/qrCodes-operations';

import { pending, rejected, isRejectedAction } from 'shared/utils/utils';

const initialState = {
  notifications: [],

  loading: false,
  customLoading: false,
  error: null,
};

const fulfilled = (
  store,
  {
    payload: {
      data: { notify },
    },
  },
) => ({
  ...store,
  notifications: [...store.notifications, notify],
});

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationsRequest.pending, pending)
      .addCase(fetchNotificationsRequest.fulfilled, (store, { payload: { data } }) => ({
        ...store,
        loading: false,
        notifications: data,
      }))
      .addCase(inactiveNotificationsRequest.pending, pending)
      .addCase(inactiveNotificationsRequest.fulfilled, (store, { payload: { data } }) => ({
        ...store,
        loading: false,
        notifications: data,
      }))

      .addCase(inactiveNotificationRequest.pending, (store, { payload }) => ({
        ...store,
        customLoading: true,
      }))
      .addCase(inactiveNotificationRequest.fulfilled, (store, { payload: { data } }) => ({
        ...store,
        customLoading: false,
        notifications: [
          ...store.notifications.filter(({ active, _id }) => active === true && _id !== data._id),
          { ...data, active: false },
          ...store.notifications.filter((item) => item._id !== data._id && item.active === false),
        ],
      }))

      .addCase(archiveFeedbackRequest.pending, pending)
      .addCase(archiveFeedbackRequest.fulfilled, fulfilled)

      .addCase(updateCompanyRequest.pending, pending)
      .addCase(updateCompanyRequest.fulfilled, fulfilled)

      .addCase(updateQRcodeRequest.pending, pending)
      .addCase(updateQRcodeRequest.fulfilled, fulfilled)

      .addCase(updateSizeQRcodeRequest.pending, pending)
      .addCase(updateSizeQRcodeRequest.fulfilled, fulfilled)

      .addCase(deleteQRcodeRequest.pending, pending)
      .addCase(deleteQRcodeRequest.fulfilled, fulfilled)

      .addCase(updateEmployeeRequest.pending, pending)
      .addCase(updateEmployeeRequest.fulfilled, fulfilled)

      .addCase(deleteEmployeeRequest.pending, pending)
      .addCase(deleteEmployeeRequest.fulfilled, fulfilled)

      .addMatcher(isRejectedAction, rejected);
  },
});

export default notificationsSlice.reducer;
