import NotificationsIcon from '@mui/icons-material/Notifications';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import WorkIcon from '@mui/icons-material/Work';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

export const DATA = [
  {
    id: 1,
    title: 'notifications',
    link: '/notifications',
    icon: <NotificationsIcon />,
    private: true,
  },
  {
    id: 2,
    title: 'restaurants',
    link: '/qr-codes',
    icon: <RestaurantIcon />,
    private: true,
  },
  {
    id: 3,
    title: 'employees',
    link: '/employees',
    icon: <WorkIcon />,
    private: true,
  },
  {
    id: 4,
    title: 'profile',
    link: '/profile',
    icon: <AccountBoxIcon />,
    private: true,
  },
];
