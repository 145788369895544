export const styles = {
  primary: {
    width: '100%',
    backgroundColor: 'primary.main',
    color: 'white',
    borderColor: 'primary.main',
    borderRadius: '20px',
    padding: '16px',

    textTransform: 'none',
    ':hover': {
      backgroundColor: 'primary.main',
      borderColor: 'primary.main',
    },
  },
  secondary: {
    minWidth: '178px',
    backgroundColor: 'primary.main',
    color: 'white',
    borderColor: 'primary.main',
    borderRadius: '100px',
    padding: '10px 24px',

    textTransform: 'none',
    ':hover': {
      backgroundColor: 'primary.main',
      borderColor: 'primary.main',
    },
  },
  disabled: {
    width: '100%',
    borderRadius: '20px',
    padding: '16px',
    backgroundColor: 'background.main',
    textTransform: 'none',
  },
  primarySmall: {
    minWidth: '110px',
    backgroundColor: 'primary.main',
    color: 'white',
    borderColor: 'primary.main',
    borderRadius: '100px',
    padding: '10px 24px',

    textTransform: 'none',
    ':hover': {
      backgroundColor: 'primary.main',
      borderColor: 'primary.main',
    },
  },
  secondarySmall: {
    minWidth: '110px',
    backgroundColor: '#fff',
    color: 'primary.main',
    border: '1px solid',
    borderColor: 'divider',
    borderRadius: '100px',
    padding: '10px 24px',

    textTransform: 'none',
    ':hover': {
      backgroundColor: '#fff',
      borderColor: 'primary.main',
    },
  },
};
