import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

import Loader from 'shared/components/Loader';
import PrivateRoute from 'shared/components/PrivateRoute';
import PublicRoute from 'shared/components/PublicRoute/PublicRoute';

const SignInPage = lazy(() => import('pages/SignInPage'));
const SignUpPage = lazy(() => import('pages/SignUpPage'));
const CreatePasswordPage = lazy(() => import('pages/CreatePasswordPage'));

const ForgotPasswordPage = lazy(() => import('pages/ForgotPasswordPage'));
const UpdatePasswordPage = lazy(() => import('pages/UpdatePasswordPage'));

const MainPage = lazy(() => import('pages/MainPage'));

const AddCompanyPage = lazy(() => import('pages/AddCompanyPage'));
const UpdateCompanyPage = lazy(() => import('pages/UpdateCompanyPage'));

const QRcodesPage = lazy(() => import('pages/QRcodesPage'));
const AddQRcodePage = lazy(() => import('pages/AddQRcodePage'));
const AddQRcodeStylePage = lazy(() => import('pages/AddQRcodeStylePage'));
const EditQRcodePage = lazy(() => import('pages/EditQRcodePage'));

const AddFeedbackPage = lazy(() => import('pages/AddFeedbackPage'));

const ProfilePage = lazy(() => import('pages/ProfilePage'));
const EditProfilePage = lazy(() => import('pages/EditProfilePage'));

const EmployeesPage = lazy(() => import('pages/EmployeesPage'));
const AddEmployeePage = lazy(() => import('pages/AddEmployeePage'));
const EditEmployeePage = lazy(() => import('pages/EditEmployeePage'));

const FAQPage = lazy(() => import('pages/FAQPage'));
const ContactUs = lazy(() => import('pages/ContactUsPage'));
const About = lazy(() => import('pages/AboutPage'));

const NotificationsPage = lazy(() => import('pages/NotificationsPage'));

const TariffsPage = lazy(() => import('pages/TariffsPage'));

const NotFoundPage = lazy(() => import('pages/NotFoundPage'));

function UserRoutes() {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/create-password" element={<CreatePasswordPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/update-password" element={<UpdatePasswordPage />} />
        <Route path="/add-feedback" element={<AddFeedbackPage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/" element={<MainPage />} />

        <Route element={<PrivateRoute />}>
          <Route path="/add-company" element={<AddCompanyPage />} />
          <Route path="/update-company" element={<UpdateCompanyPage />} />
          <Route path="/qr-codes" element={<QRcodesPage />} />
          <Route path="/add-qrcode" element={<AddQRcodePage />} />
          <Route path="/add-qrcode-style" element={<AddQRcodeStylePage />} />
          <Route path="/edit-qrcode/:id" element={<EditQRcodePage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/edit-profile" element={<EditProfilePage />} />
          <Route path="/employees" element={<EmployeesPage />} />
          <Route path="/add-employee" element={<AddEmployeePage />} />
          <Route path="/edit-employee" element={<EditEmployeePage />} />
          <Route path="/notifications" element={<NotificationsPage />} />
          <Route path="/tariffs" element={<TariffsPage />} />
        </Route>

        <Route element={<PublicRoute />}>
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/signup" element={<SignUpPage />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  );
}

export default UserRoutes;
