import PropTypes from 'prop-types';

import { Button, Box } from '@mui/material';
import { styles } from './CustomButtonTheme';

function CustomButton({ children, style, type, onClick, disabled }) {
  const variant = style === 'primary' ? 'outlined' : 'contained';
  let btnStyle = style === 'primary' ? styles.primary : styles.secondary;
  if (style === 'primary-small' || style === 'secondary-small') {
    btnStyle = style === 'primary-small' ? styles.primarySmall : styles.secondarySmall;
  }

  return (
    <Box
      sx={{
        textAlign: style === 'primary-small' || style === 'secondary-small' ? 'left' : 'center',
        p: 0,
      }}>
      <Button
        type={type}
        onClick={onClick}
        disabled={disabled}
        variant={variant}
        sx={disabled ? styles.disabled : btnStyle}>
        {children}
      </Button>
    </Box>
  );
}
export default CustomButton;

CustomButton.defaultProps = {
  onClick: () => {},
  type: 'button',
  style: 'secondary',
  disabled: false,
};
CustomButton.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};
