import { useEffect } from 'react';
import { Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import useAuthState from 'shared/hooks/useAuthState';

import NavigationBar from './modules/NavigationBar';
import UserRoutes from './UserRoutes';

import CustomContainer from 'shared/components/CustomContainer';
import ContentContainer from 'shared/components/ContentContainer';
import Loader from 'shared/components/Loader';

import { getCurrentRequest } from 'redux/auth/auth-operations';
import { getCompaniesRequest } from 'redux/companies/companies-operations';
import { fetchNotificationsRequest } from 'redux/notifications/notifications-operation';

function App() {
  const dispatch = useDispatch();
  const { token, isLogin } = useAuthState();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(getCurrentRequest());
  }, [dispatch]);

  useEffect(() => {
    if (isLogin) {
      dispatch(getCompaniesRequest());
      dispatch(fetchNotificationsRequest());
    }
  }, [token, isLogin]);

  //fix problem with Masonry Mui Resize Error
  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <CustomContainer>
        {pathname !== '/add-feedback' && <NavigationBar />}
        <Box
          component="main"
          sx={{
            position: 'relative',
            bgcolor: 'background.main',
            height: 'auto',
            // overflow: 'scroll',
            paddingTop: (pathname === '/add-feedback' || pathname === '/notifications') && '0',
          }}>
          <ContentContainer>
            <UserRoutes />
          </ContentContainer>
        </Box>
      </CustomContainer>
    </Suspense>
  );
}

export default App;
