import { Container } from '@mui/material';
import { styles } from './CustomContainerTheme';

function CustomContainer({ children }) {
  return (
    <Container sx={styles.container} disableGutters maxWidth={false}>
      {children}
    </Container>
  );
}

export default CustomContainer;
