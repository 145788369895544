import { stylesVars } from 'styles/stylesVars';

export const styles = {
  mainBox: {
    '@media screen and (max-width: 744px)': {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '75vh',
    },
    '@media screen and (min-width: 745px)': {
      flexGrow: '1',
    },
  },
  list: {
    '@media screen and (max-width: 744px)': { flexGrow: '1' },
    '@media screen and (min-width: 744px)': {
      marginBottom: '50px',
    },
  },
  bottomMenu: {
    '@media screen and (max-width: 744px)': { width: 'auto', flexShrink: '0' },
  },
  listItem: {
    marginBottom: '16px',
    '@media screen and (min-width: 744px)': {
      margin: '0',
      padding: '14px 0',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'flex-end',
  },
  icon: {
    fontSize: '24px',
    marginRight: '10px',
    color: stylesVars.light.primary,

    '@media screen and (min-width: 744px)': {
      marginRight: '6px',
    },
  },
  text: {
    fontSize: stylesVars.fonts.fontSize,
    '@media screen and (min-width: 744px)': {
      fontSize: '14px',
    },
  },
};
