import { styled } from '@mui/material/styles';
import { Switch } from '@mui/material';

function CustomSwitcher({ onClick, checked, disabled }) {
  const StyledSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} autoComplete="lang" />
  ))(({ theme }) => ({
    width: 52,
    height: 36,
    padding: 4,
    '& .MuiSwitch-switchBase': {
      padding: 4,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          padding: 4,
          backgroundColor: 'primary.main',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: 'primary.main',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: 'background.default',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        border: '6px solid transparent',
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 24,
      height: 24,
    },
    '& .MuiSwitch-track': {
      borderRadius: '100px',
      backgroundColor: '#79747E',

      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));
  return <StyledSwitch onClick={onClick} checked={checked} disabled={disabled} />;
}
export default CustomSwitcher;
