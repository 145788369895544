export const styles = {
  dialog: { position: 'absolute', zIndex: 2 },
  wrapper: {
    padding: '16px',
    paddingTop: '125px',
    height: '100%',

    margin: '0 auto',
    width: '330px',
  },
};
