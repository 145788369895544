import { useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '@mui/material';
import { List, ListItem, SvgIcon, Typography, Box, Tooltip } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

import useAuthState from 'shared/hooks/useAuthState';
import useCompaniesState from 'shared/hooks/useCompaniesState';

import LinkTariff from 'shared/components/LinkTariff';
import CustomLink from 'shared/components/CutomLink';

import useNoitificationsState from 'shared/hooks/useNotificationsState';

import { signoutRequest } from 'redux/auth/auth-operations';

import { DATA } from 'shared/constants/menu/profileMenuData';
import { styles } from './ProfileMenuTheme';

function ProfileMenu({ closeModal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const matchSM = useMediaQuery('(max-width:744px)');

  const { companies } = useCompaniesState();
  const qrCodes = companies?.filter((item) => item.qrCodes);

  const { notifications } = useNoitificationsState();
  const notification = notifications?.filter((notification) => notification?.active === true)?.length || 0;

  const { user } = useAuthState();
  const { firstName, lastName, phone, positionInCompany } = user;

  return (
    <Box sx={styles.mainBox}>
      <List sx={styles.list}>
        {DATA.map(({ id, title, icon, link }) => (
          <ListItem key={id} sx={styles.listItem} onClick={closeModal}>
            <Link to={link} style={styles.link} onClick={closeModal}>
              {title === 'notifications' && notification > 0 && matchSM && (
                <Box sx={styles.wrapperNotification}>
                  <Typography sx={styles.textNotification}>{notification}</Typography>
                </Box>
              )}
              {!matchSM && <SvgIcon sx={styles.icon}>{icon}</SvgIcon>}
              <Typography sx={styles.text}>{t(`profileMenu.${title}`)}</Typography>
              {matchSM && <SvgIcon sx={styles.icon}>{icon}</SvgIcon>}
              {!matchSM && title === 'notifications' && notification > 0 && (
                <Box sx={styles.wrapperNotification}>
                  <Typography sx={styles.textNotification}>{notification}</Typography>
                </Box>
              )}
              {title === 'restaurants' && !qrCodes?.length && (
                <Tooltip title={t('tooltip.addQrs')}>
                  <SvgIcon sx={{ fontSize: '16px', ml: '10px', color: 'orange' }}>
                    <ErrorOutline />
                  </SvgIcon>
                </Tooltip>
              )}
              {title === 'profile' &&
                Object.values({ firstName, lastName, phone, positionInCompany }).filter((item) => item && item).length <
                  4 && (
                  <Tooltip title={t('tooltip.fillProfile')}>
                    <SvgIcon sx={{ fontSize: '16px', ml: '10px', color: 'orange' }}>
                      <ErrorOutline />
                    </SvgIcon>
                  </Tooltip>
                )}
            </Link>
          </ListItem>
        ))}
      </List>
      {matchSM && (
        <Box sx={styles.bottomMenu}>
          <LinkTariff />
          <CustomLink
            text={t('profileMenu.signOut')}
            onClick={() => {
              dispatch(signoutRequest());
              closeModal();
            }}
          />
        </Box>
      )}
    </Box>
  );
}
export default ProfileMenu;
