import { useState } from 'react';
import i18next from 'i18next';

import { Typography, Box } from '@mui/material';

import CustomSwitcher from 'shared/components/CustomSwitcher';

import { styles } from './LangSwitcherTheme';

function LangSwitcher() {
  const [lang, setLang] = useState(i18next.language);

  const handleSwitchLang = ({ target }) => {
    const { checked } = target;
    setLang(checked ? 'ua' : 'en');

    i18next.changeLanguage(checked ? 'ua' : 'en');
  };

  return (
    <Box sx={styles.wrapperSwitch}>
      <Typography sx={styles.lang}>EN</Typography>
      <CustomSwitcher onClick={handleSwitchLang} checked={lang === 'ua' ? true : false} />
      <Typography sx={styles.lang}>UA</Typography>
    </Box>
  );
}
export default LangSwitcher;
