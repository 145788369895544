import { stylesVars } from 'styles/stylesVars';

export const styles = {
  mainBox: {
    '@media screen and (max-width: 744px)': {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '75vh',
    },
  },
  list: {
    '@media screen and (max-width: 744px)': { flexGrow: '1' },
    '@media screen and (min-width: 744px)': {
      marginBottom: '50px',
    },
  },
  bottomMenu: {
    '@media screen and (max-width: 744px)': { width: 'auto', flexShrink: '0' },
  },
  listItem: {
    marginBottom: '16px',
    padding: '16px',
    justifyContent: 'flex-end',

    '@media screen and (min-width: 744px)': {
      justifyContent: 'flex-start',
      margin: '0',
      padding: '14px 0',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
  },
  wrapperNotification: {
    marginRight: '10px',
    bgcolor: stylesVars.light.primary,
    padding: '0 4px',
    width: 'auto',
    height: '16px',
    borderRadius: '100px',
    textAlign: 'center',
  },
  textNotification: {
    fontSize: '11px',
    color: '#fff',
    fontWeight: '500',
  },
  text: {
    fontSize: stylesVars.fonts.fontSize,
    marginRight: '10px',
    '@media screen and (min-width: 744px)': {
      marginRight: '6px',
      fontSize: '14px',
    },
  },
  icon: {
    fontSize: '24px',
    color: stylesVars.light.primary,
    '@media screen and (min-width: 744px)': {
      marginRight: '6px',
    },
  },
};
