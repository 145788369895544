import { useNavigate, Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Box, Divider, Typography } from '@mui/material';

import useAuthState from 'shared/hooks/useAuthState';

import CommonMenu from '../Menu/CommonMenu';
import ProfileMenu from '../Menu/ProfileMenu';

import LangSwitcher from 'shared/components/LangSwitcher';
import Logo from 'shared/components/Logo';
import CustomButton from 'shared/components/CustomButton';

import { styles } from './AsideMenuDesktopTheme';

function AsideMenuDesktop() {
  const { t } = useTranslation();
  const { isLogin } = useAuthState();
  const navigate = useNavigate();

  return (
    <Box component="aside" sx={styles.wrapper}>
      <Box component="nav" sx={styles.nav}>
        <Box sx={styles.logoWrapper}>
          <Link to="/">
            <Logo />
          </Link>
        </Box>
        {isLogin && <ProfileMenu />}
        {isLogin && <Divider />}
        <CommonMenu />
        <Box sx={{ width: '100%', flexShrink: '0' }}>
          {!isLogin && (
            <CustomButton type="button" onClick={() => navigate('/signin')} style="primary" disabled={false}>
              <Typography>{t('buttons.signIn')}</Typography>
            </CustomButton>
          )}
          <LangSwitcher />
        </Box>
      </Box>
    </Box>
  );
}

export default AsideMenuDesktop;
