import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '@mui/material';
import { List, ListItem, SvgIcon, Typography, Box } from '@mui/material';

import LangSwitcher from 'shared/components/LangSwitcher';
import LinkTariff from 'shared/components/LinkTariff';

import useAuthState from 'shared/hooks/useAuthState';

import { DATA } from 'shared/constants/menu/commonMenuData';
import { styles } from './CommonMenuTheme';

function CommonMenu({ closeModal }) {
  const { t } = useTranslation();
  const matchSM = useMediaQuery('(max-width:744px)');
  const { isLogin } = useAuthState();

  return (
    <Box sx={styles.mainBox}>
      <List sx={styles.list}>
        {DATA.map(
          ({ id, title, icon, link, login }) =>
            login &&
            isLogin && (
              <ListItem key={id} sx={styles.listItem} onClick={closeModal}>
                <Link style={styles.link} to={link} onClick={closeModal}>
                  <SvgIcon sx={styles.icon}>{icon}</SvgIcon>
                  <Typography sx={styles.text}>{t(`commonMenu.${title}`)}</Typography>
                </Link>
              </ListItem>
            ),
        )}
        {DATA.map(
          ({ id, title, icon, link, login }) =>
            !login && (
              <ListItem key={id} sx={styles.listItem} onClick={closeModal}>
                <Link style={styles.link} to={link} onClick={closeModal}>
                  <SvgIcon sx={styles.icon}>{icon}</SvgIcon>
                  <Typography sx={styles.text}>{t(`commonMenu.${title}`)}</Typography>
                </Link>
              </ListItem>
            ),
        )}
      </List>
      {matchSM && (
        <Box styles={styles.bottomMenu}>
          <LinkTariff />
          <LangSwitcher />
        </Box>
      )}
    </Box>
  );
}
export default CommonMenu;
