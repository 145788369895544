import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';

export const DATA = [
  {
    id: 1,
    title: 'tariffs',
    link: '/tariffs',
    icon: <AttachMoneyIcon />,
    login: true,
  },
  {
    id: 2,
    title: 'faq',
    link: '/faq',
    icon: <HelpOutlineIcon />,
    login: false,
  },
  {
    id: 3,
    title: 'aboutApp',
    link: '/about',
    icon: <InfoOutlinedIcon />,
    login: false,
  },
  {
    id: 4,
    title: 'contact',
    link: '/contact-us',
    icon: <PhoneAndroidOutlinedIcon />,
    login: false,
  },
];
