import { createSlice } from '@reduxjs/toolkit';
import {
  getQRsRequest,
  addQRcodeRequest,
  updateQRcodeRequest,
  updateSizeQRcodeRequest,
  deleteQRcodeRequest,
} from './qrCodes-operations';

import { pending, rejected, isRejectedAction } from 'shared/utils/utils';

const initialState = {
  qrCodes: [],

  loading: false,
  customLoad: false,
  error: null,
};

const qrCodesSlice = createSlice({
  name: 'qrCodes',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getQRsRequest.pending, pending)
      .addCase(getQRsRequest.fulfilled, (store, { payload: { data } }) => ({ qrCodes: data || [] }))

      .addCase(addQRcodeRequest.pending, pending)
      .addCase(
        addQRcodeRequest.fulfilled,
        (
          store,
          {
            payload: {
              data: { data: qrObj },
            },
          },
        ) => ({
          qrCodes: [...store.qrCodes, qrObj],
        }),
      )
      .addCase(updateQRcodeRequest.pending, pending)
      .addCase(updateQRcodeRequest.fulfilled, (store, { payload: { data } }) => ({
        qrCodes: [...store.qrCodes.filter(({ _id }) => _id !== data._id), data],
      }))

      .addCase(updateSizeQRcodeRequest.pending, pending)
      .addCase(updateSizeQRcodeRequest.fulfilled, (store, { payload: { data } }) => ({
        qrCodes: [...store.qrCodes.filter(({ _id }) => _id !== data._id), data],
      }))

      .addCase(deleteQRcodeRequest.pending, (store, _) => ({ ...store, customLoad: true }))
      .addCase(deleteQRcodeRequest.fulfilled, (store, { payload: { id } }) => ({
        customLoad: false,
        qrCodes: [...store.qrCodes.filter(({ _id }) => _id !== id)],
      }))

      .addMatcher(isRejectedAction, rejected);
  },
});
export default qrCodesSlice.reducer;
