import axios from 'axios';

import { instance } from './instance';

const setToken = (token = '') => {
  if (token) {
    return (instance.defaults.headers.authorization = `Bearer ${token}`);
  }
  instance.defaults.headers.authorization = '';
};

export async function signup(data) {
  const { data: result } = await instance.post('/user/signup', data);
  return result;
}

export async function confirmSignUp(data) {
  const { data: result } = await instance.post('user/confirm-signup', data);
  return result;
}

export async function createUser(data) {
  const { data: result } = await instance.post('/user/create', data);
  setToken(result.data.token);
  return result;
}

export async function signin(data) {
  const { data: result } = await instance.post('/user/login', data);
  setToken(result.data.token);
  return result;
}

export async function googleAuth(data) {
  const { data: result } = await instance.post('/user/google/login', data);
  setToken(result.data.token);
  return result;
}

export async function getGoogleUserInfo(token) {
  try {
    const { data } = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    throw error;
  }
}

export async function facebookAuth(data) {
  const { data: result } = await instance.post('/user/facebook/login', data);
  setToken(result.data.token);
  return result;
}

export async function forgotPassword(data) {
  const { data: result } = await instance.post('/user/forgot-password', data);
  return result;
}
export async function updatePassword(data) {
  const { data: result } = await instance.post('/user/update-password', data);
  return result;
}

export async function signout(token) {
  if (token) {
    setToken('');
    return null;
  }
  setToken('');
}

export async function updateUser(data) {
  const { data: result } = await instance.patch('/user/update-user', data);
  return result;
}

export async function getCurrent(token) {
  try {
    setToken(token);
    const { data: result } = await instance.get('/user/current');
    return { ...result, token };
  } catch (error) {
    setToken('');
    throw error;
  }
}
