import { instance } from './instance';

export async function addEmployee(data) {
  const { data: result } = await instance.post('/user/create-employee', data);
  return result;
}
export async function deleteEmployee(data) {
  const { data: result } = await instance.delete(`/user/delete-employee/${data}`);
  return { _id: data, ...result };
}

export async function updateEmployee(data) {
  const { data: result } = await instance.patch('/user/update-employee', data);
  return result;
}
