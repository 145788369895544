export const stylesVars = {
  light: {
    primary: '#6750A4',
    headings: '#1C1B1F',
    text: '#49454F',
    background: '#F7F6FA',
    backgroundSecondary: '#FFFBFE',
    unactive: '#8C809E',
    strockes: '#79747E',
  },
  dark: {},
  fonts: {
    roboto: 'Roboto',
    styleFont: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
