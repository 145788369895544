import { instance } from './instance';

export async function getCompanies() {
  const { data: result } = await instance.get('/companies');
  return result;
}

export async function addCompany(data) {
  const { data: result } = await instance.post('/company/add', data);
  return result;
}
export async function updateCompany(data) {
  const { data: result } = await instance.put('/company', data);
  return result;
}
export async function deleteCompany(id) {
  const { data: result } = await instance.delete(`/company/${id}`);
  return id;
}
