import { createSlice } from '@reduxjs/toolkit';
import {
  getFeedbacksRequest,
  addTagsRequest,
  addCommentRequest,
  archiveFeedbackRequest,
  detachTagRequest,
} from './feedbacks-operations';

import { pending, rejected, isRejectedAction } from 'shared/utils/utils';

const initialState = {
  feedbacks: [],

  requestLength: 0,
  totalCount: 0,

  loading: false,
  customLoading: false,
  error: null,
};

const feedbacksSlice = createSlice({
  name: 'feedbacks',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getFeedbacksRequest.pending, pending)
      .addCase(getFeedbacksRequest.fulfilled, (store, { payload: { data } }) => ({
        ...store,
        loading: false,
        requestLength: data?.requestLength,
        totalCount: data?.totalCount,

        feedbacks: data?.data?.filter(({ archive }) => archive === false),
      }))

      .addCase(addTagsRequest.pending, pending)
      .addCase(addTagsRequest.fulfilled, (store, { payload: { data } }) => ({
        ...store,
        loading: false,
        feedbacks: [
          ...store.feedbacks.map((feedback) => {
            if (feedback._id === data._id) {
              return data;
            } else {
              return feedback;
            }
          }),
        ],
      }))

      .addCase(detachTagRequest.pending, pending)
      .addCase(detachTagRequest.fulfilled, (store, { payload: { data } }) => ({
        ...store,
        loading: false,
        feedbacks: [
          ...store.feedbacks.map((feedback) => {
            if (feedback._id === data.updFeedback._id) {
              return { ...feedback, tags: feedback.tags.filter(({ _id }) => _id !== data.tag._id) };
            } else {
              return feedback;
            }
          }),
        ],
      }))

      .addCase(addCommentRequest.pending, pending)
      .addCase(addCommentRequest.fulfilled, (store, { payload: { data } }) => ({
        ...store,
        loading: false,
        feedbacks: store.feedbacks.map((feedback) => (feedback._id === data._id ? data : feedback)),
      }))

      .addCase(archiveFeedbackRequest.pending, pending)
      .addCase(
        archiveFeedbackRequest.fulfilled,
        (
          store,
          {
            payload: {
              data: { feedback },
            },
          },
        ) => ({
          ...store,
          loading: false,
          feedbacks: store.feedbacks.filter(({ _id }) => _id !== feedback?._id),
        }),
      )

      .addMatcher(isRejectedAction, rejected);
  },
});

export default feedbacksSlice.reducer;
