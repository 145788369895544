import { createOperation } from 'shared/utils/utils';

import { fetchFeedbacks, addTag, addComment, archiveFeedback, detachTag } from 'shared/api/feedbacks';

export const getFeedbacksRequest = createOperation('feedbacks/fetch', fetchFeedbacks);

export const archiveFeedbackRequest = createOperation('feedbacks/archive', archiveFeedback);

export const addTagsRequest = createOperation('feedbacks/addTag', addTag);
export const addCommentRequest = createOperation('feedback/comment', addComment);

export const detachTagRequest = createOperation('feedback/detachTag', detachTag);
