import { createContext, useReducer } from 'react';

import CustomNotification from 'shared/components/CustomNotification';

export const NotificationContext = createContext();

function NotificationProvider({ children }) {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'ADD_NOTIFICATION':
        return [...state, { ...action.payload }];
      case 'REMOVE_NOTIFICATION':
        return state.filter((el) => el.id !== action.id);
      default:
        return state;
    }
  }, []);

  return (
    <NotificationContext.Provider value={dispatch}>
      {state.map((item) => (
        <CustomNotification key={item.id} dispatch={dispatch} {...item} />
      ))}
      {children}
    </NotificationContext.Provider>
  );
}

export default NotificationProvider;
