import { Link, Box, IconButton } from '@mui/material';
import { KeyboardBackspace } from '@mui/icons-material';
import { stylesVars } from 'styles/stylesVars';

function CustomLink({ text, path, onClick, style, type, justifyContent, active, hover }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: justifyContent ? justifyContent : 'center',
        marginTop: '16px',
        gap: '7px',
      }}
      onClick={onClick}>
      {type === 'back' && (
        <IconButton sx={{ ...stylesVars.flexBox }} onClick={onClick}>
          <KeyboardBackspace sx={{ fontSize: '20px' }} />
        </IconButton>
      )}
      <Box sx={{ ...stylesVars.flexBox }} onClick={onClick}>
        <Link
          sx={{
            color: active ? '#fff' : 'text.secondary',
            fontSize: '16px',
            fontWeight: '400',
            textDecorationLine: style ? 'none' : 'underline',
            ':hover': {
              color: hover || 'primary.main',
            },
          }}
          href={path}>
          {text}
        </Link>
      </Box>
    </Box>
  );
}

export default CustomLink;
