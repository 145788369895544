import { instance } from './instance';

export async function fetchNotifications() {
  const { data: result } = await instance.get('/notifications');
  return result;
}

export async function inactiveNotification(_id) {
  const { data: result } = await instance.put(`/notifications/${_id}`);
  return result;
}
export async function inactiveNotifications() {
  const { data: result } = await instance.get('/notifications-inactive');
  return result;
}
