import { stylesVars } from 'styles/stylesVars';

export const styles = {
  header: {
    bgcolor: 'background.secondary',
    backgroundImage: 'linear-gradient(0deg, rgba(103, 80, 164, 0.08), rgba(103, 80, 164, 0.08))',
    height: 'auto',
    padding: '0',
    height: '65px',
  },
  list: {
    height: '65px',
    ...stylesVars.flexBox,
  },
  listItem: {
    gap: '10px',
    ':hover': {
      bgcolor: 'transparent',
    },
  },
  menuIcon: {
    fontSize: '24px',
    color: stylesVars.light.text,
    ':hover': stylesVars.light.primary,
    ':active': stylesVars.light.primary,
  },
};
