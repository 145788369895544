import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './auth/auth-slice';
import companiesSlice from './companies/companies-slice';
import qrCodesSlice from './qrCodes/qrCodes-slice';
import feedbacksSlice from './feedbacks/feedbacks-slice';
import notificationsSlice from './notifications/notifications-slice';

const persistTokenConfig = {
  key: 'auth',
  storage,
  whitelist: ['token', 'ttl'],
};

const persistAuthReducer = persistReducer(persistTokenConfig, authReducer);

const rootReducer = combineReducers({
  auth: persistAuthReducer,
  companies: companiesSlice,
  qrCodes: qrCodesSlice,
  feedbacks: feedbacksSlice,
  notifications: notificationsSlice,
});

export default rootReducer;
