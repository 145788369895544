import { CircularProgress, Box } from '@mui/material';

function Loader({ size }) {
  return (
    <Box sx={{ display: 'flex', position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <CircularProgress size={size} />
    </Box>
  );
}
export default Loader;
